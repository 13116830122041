import url from './url'
import http from './index'
import qs from 'qs'
export default {
    // 用户注册
    // 获取验证码
    getMobileCode(data) {
        return http.post(url.getMobileCode, null, {
            params: data
        })
    },
    //注册
    RegisteredAccount(data) {
        // return http.post(url.RegisteredAccount, null, {
        //     params: data
        // })
        return http({
            url: url.RegisteredAccount,
            method: "post",
            data: null,
            params: data,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            }
        })
    },
    //密码登录
    login(data) {

        return http.post(url.login, null, {
            params: data
        })
    },
    // 验证码登录
    codeLogin(data) {
        return http.post(url.codeLogin, null, {
            params: data
        })
    },
    // 检查用户手机号是否已经注册
    isRegistered(data) {
        return http.post(url.isRegistered, null, {
            params: data
        })
    },
    // 修改密码 
    changePas(data) {
        return http.post(url.changePas, null, {
            params: data
        })
    },
    // 手机验证码修改密码
    codeChangePas(data, headers) {
        return http.post(url.codeChangePas, null, {
            params: data
        })
    },

    // 获取省级option
    getProvinceOption() {
        return http.get(url.getProvinceOption)
    },
    getCityOption(data) {
        return http.get(url.getCityOption, {
            params: data
        })
    },
    // 获取用户个人信息
    getUserInfo() {
        return http.get(url.getUserInfo)
    },
    // 修改用户个人信息
    changeMyInfo(params, data) {
        return http.post(
            url.changeMyInfo + '?' + 'realName=' + params.realName + '&mobile=' + params.mobile,
            data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
    },
    // 获取用户头像
    getUserP(data) {
        return http.get(url.getUserP + data)
    },
    // 获取标识
    getOnly() {
        return http.get(url.getOnly)
    },
    // 获取图片验证码
    getPCode(data) {

        return http.get(url.getPCode, {
            params: data
        })
    },
    // 验证手机号是否注册
    isMobile(data) {
        return http.post(url.isMobile, null, {
            params: data
        })
    },




    // 中小学字典
    // 获取中学年级
    getMiddleGrade() {
        return http.get(url.getMiddleGrade)
    },
    // 获取中学科目 
    getMiddleCourse() {
        return http.get(url.getMiddleCourse)
    },
    // 获取班级 
    getClasses() {
        return http.get(url.getClasses)
    },
    // 获取小学年级
    getElementaryGrade() {
        return http.get(url.getElementaryGrade)
    },
    // 获取小学科目
    getElementaryCourse() {
        return http.get(url.getElementaryCourse)
    },
    // 获取考试类型
    getExamType() {
        return http.get(url.getExamType)
    },

    // 学生管理模块
    // 获取学生分页列表
    getStudentList(data) {
        return http.get(url.getStudentList, {
            params: data
        })
    },
    // 获取所有学生列表
    getAllStudentList(grade, classes) {
        return http.get("school-report/student/" + grade + "/" + classes)
    },
    //修改学生信息
    changeStudengts(data) {
        return http({
            method: 'put',
            url: url.getStudentList,
            data: {
                ...data
            },
            headers: {
                "Content-Type": "application/json;charset=UTF-8"
            }
        })
    },
    // 添加学生
    addStudentList(data) {
        return http({
            method: 'post',
            url: url.getStudentList,
            data: {
                ...data
            },
            headers: {
                "Content-Type": "application/json;charset=UTF-8"
            }
        })
    },
    // 删除学生
    deleteStudent(data) {
        return http.delete(
            url.deleteStudent + data,
        )
    },
    // 获取年级option
    getGrade() {
        return http.get(url.getGrade)
    },
    // 获取班级option
    getClass(data) {
        return http.get(url.getClass, {
            params: data
        })
    },
    // 获取小学列表
    getPrimarySchool(data) {
        return http.get(url.getPrimarySchool, {
            params: data
        })
    },
    // 获取初中列表
    getMiddleSchool(data) {
        return http.get(url.getMiddleSchool, {
            params: data
        })
    },

    // 新建成绩单模块
    // 获取预览列表
    getReportByID(data, val) {
        return http.get(
            url.getReportByID + data, {
                params: val
            }
        )
    },
    // 发送成绩单
    sengReportByID(data) {
        return http.post(url.sengReportByID, null, {
            params: data
        })
    },
    //  新增成绩单信息  
    addReport(data) {
        return http({
            method: 'post',
            url: url.addReport,
            data: data,
            headers: {
                "Content-Type": "application/json;charset=UTF-8"
            }
        })
    },
    // 修改成绩单
    changeReport(data) {
        return http({
            method: 'put',
            url: url.addReport,
            data,
            headers: {
                "Content-Type": "application/json;charset=UTF-8"
            }
        })
    },
    // 获取发送记录
    getReportedList(data) {
        return http.get(url.getReportedList, {
            params: data
        })
    },
    // 获取草稿箱列表 
    getDraft(data) {
        return http({
            method: 'get',
            url: url.getDraft,
            params: data,
            headers: {
                ['Access-Control-Expose-Headers']: "X-Total-Count"
            }
        })
    },
    // 删除成绩 
    deleteDra(id) {
        return http.delete(url.deleteDra + id)
    },
    // 获取班级列表
    getClassList() {
        return http.get(url.getclasslist)
    },
    // 获取文章列表
    getHelpList(app, catalog, data) {
        return http({
            method: 'get',
            url: "cms-service/open/list/" + app + "/" + catalog,
            params: data,
            headers: {
                ['Access-Control-Expose-Headers']: "X-Total-Count"
            }
        })
    },
    // 获取文章详情
    getHelp(id) {
        return http({
            method: 'get',
            url: "cms-service/open/read/" + id,
        })
    }
}