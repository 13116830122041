export default {
    // 用户注册模块
    // 获取手机验证码
    getMobileCode: 'authority-center/open/sms_code',
    // 注册
    RegisteredAccount: 'authority-center/open/register',
    //用户登录
    // 密码登录
    login: 'authority-center/open/login_pc_ent',
    //验证码登录
    codeLogin: 'authority-center/open/sms_login_pc_ent',
    //检查用户手机号是否已经注册
    isRegistered: 'authority-center/open/check_mobile_ent',
    // 修改用户密码
    changePas: "authority-center/common/changepwd",
    // 获取省级option
    getProvinceOption: "dict-service/open/province",
    // 获取市级option 
    getCityOption: "dict-service/open/district",
    // 获取小学列表
    getPrimarySchool: "dict-service/open/school/elementary",
    // 获取中学列表
    getMiddleSchool: "dict-service/open/school/middle",
    // 获取用户个人信息 
    getUserInfo: "authority-center/common/account/info",
    // 获取用户头像 
    getUserP: "authority-center/common/avatar/",
    // 获取标识
    getOnly: "authority-center/open/sessionid",
    // 获取图片验证码
    getPCode: "authority-center/open/verify_code",
    // 手机验证码修改密码
    codeChangePas: "authority-center/common/resetpwd",
    // 验证手机号是否注册
    isMobile: "authority-center/open/check_mobile_ent",
    // 修改用户个人信息
    changeMyInfo: "authority-center/common/account/updateInfo",

    // 中学
    // 获取中学年级
    getMiddleGrade: "dict-service/open/school/middle/grade",
    // 获取中学科目 
    getMiddleCourse: "dict-service/open/school/middle/course",
    // 获取班级 
    getClasses: "dict-service/open/school/middle/classes",
    // 小学
    // 获取小学年级
    getElementaryGrade: "dict-service/open/school/elementary/grade",
    // 获取小学科目
    getElementaryCourse: "dict-service/open/school/elementary/course",
    // 获取考试类型 
    getExamType: "dict-service/open/school/examType",



    // 学生管理模块
    // 获取学生分页 修改学生信息 新增学生
    getStudentList: "school-report/student/",
    // 删除学生
    deleteStudent: "school-report/student/",
    // 获取年级option
    getGrade: "school-report/student/getGrade",
    // 获取班级option
    getClass: "school-report/student/getClasses",

    // 新建成绩单模块
    // 获取预览列表
    getReportByID: "school-report/report/",
    // 发送成绩单
    sengReportByID: "school-report/report/sendAll",
    // 新增成绩单
    addReport: 'school-report/report/',
    // 获取发送记录班级信息
    getclasslist: "school-report/report/catelog",
    // 获取发送记录
    getReportedList: "school-report/report/send_list",
    // 获取草稿箱列表
    getDraft: "school-report/report/draft",
    // 删除成绩单
    deleteDra: "school-report/report/"


}