import axios from "axios";
import qs from "qs";
import {
  Message
} from "element-ui";

const tokenKey = "Authorization";
const request = axios.create({
  baseURL: "https://api.joyousoa.com/",
  // baseURL: "http://192.168.101.200:9002/",
  // baseURL: "https://api.prosuntech.com/",
  timeout: 140000,
  headers: {
    // 'Content-Type': 'application/json;charset=UTF-8',
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    // 'Content-Type': 'multipart/form-data;  boundary=----WebKitFormBoundary2aXQlWf6Ry3KweQt'
    "Redirect-Header": false,
  },
});

const baseHeader = () => {
  const token = window.localStorage.getItem(tokenKey);
  if (!token) return null;
  return {
    [tokenKey]: "Bearer" + " " + token,
  };
};

request.interceptors.request.use(
  (config) => {
    config.headers = Object.assign({}, baseHeader(), config.headers);
    if (!config.url) {
      Message.error({
        showClose: true,
        message: "接口地址错误",
        type: "error",
        duration: 3 * 1000,
      });
    }
    if (config.url == "school-report/student/") return config;
    if (config.url == "school-report/report/" && config.method == "post") return config;
    if (config.url == "school-report/report/" && config.method == "put") return config;
    // if (config.url)
    config.data = qs.stringify(config.data);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 401没有认证   403没有权限
request.interceptors.response.use(
  (response) => {
    // localStorage.needchangepassword = response.headers.needchangepassword
    // if (response.data.token && response.data.token !== '' && response.data.token !== null) {
    //   localStorage.setItem(tokenKey, response.data.token)
    // }
    if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
      Message({
        message: response.statusText || "Error",
        type: "error",
        duration: 5 * 1000,
      });
      return Promise.reject(new Error(response.statusText || "Error"));
    } else {
      return response;
    }
  },
  (error) => {
    let message = error.statusText;
    if (error.response.status === 404) {
      message = "接口访问失败";
    }
    Message({
      showClose: true,
      message,
      type: "error",
      duration: 3 * 1000,
    });
    let response = error.response;
    if (
      response &&
      (response.status === 200 ||
        response.status === 304 ||
        response.status === 400 ||
        response.status === 404)
    ) {
      return response.data;
    }

    if (response && (response.status === 403 || response.status === 401)) {
      return Message({
        showClose: true,
        message: "没有访问权限,请重新登录",
        type: "error",
        duration: 3 * 1000,
      });
    }
    return Promise.reject(error);
  }
);

export default request;